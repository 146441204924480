import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { LOGIN_USER, LOGOUT_USER } from '../../constants/routes';
import { ILogon, ILogonResponse, IRefreshToken } from '../../types/user';
import { config } from '../../config';

const { REACT_APP_LEONCO_API_URL } = config;

export const authApi = createApi({
  reducerPath: 'authentication',
  baseQuery: fetchBaseQuery({
    baseUrl: REACT_APP_LEONCO_API_URL,
    prepareHeaders: (headers) => {
      headers.set('Accept', 'application/json');
      headers.set('Content-Type', 'application/json');
      headers.set('Cache-Control', 'no-cache');
      headers.set('x-authentication-context', 'admin-web');
      headers.set('Access-Control-Allow-Origin', '*');

      return headers;
    },
    method: 'POST',
  }),
  endpoints: (builder) => ({
    loginUser: builder.mutation<ILogonResponse, ILogon>({
      query: ({ ...user }) => ({
        url: LOGIN_USER,
        body: user,
        method: 'POST',
      }),
    }),
    logOutUser: builder.mutation<void, IRefreshToken>({
      query: ({ ...refreshToken }) => ({
        url: LOGOUT_USER,
        body: refreshToken,
        method: 'POST',
      }),
    }),
  }),
});

export const { useLoginUserMutation, useLogOutUserMutation } = authApi;
