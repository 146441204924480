import { createApi } from '@reduxjs/toolkit/query/react';
import {
  DELETE_FUEL_STATIONS,
  FETCH_FUEL_STATIONS,
  UPDATE_FUEL_STATIONS,
} from '../../constants/routes';
import { IFuelStation, IFuelStationResponse } from '../../types/inventory';
import { IQuery } from '../../types/default';
import { staggeredBaseQuery } from '../base-query';

export const stationApi = createApi({
  reducerPath: 'stations',
  baseQuery: staggeredBaseQuery,
  endpoints: (builder) => ({
    fetchAllStations: builder.query<IFuelStationResponse, IQuery>({
      query: (params) => ({
        url: FETCH_FUEL_STATIONS,
        params,
        method: 'GET',
      }),
    }),
    deleteStation: builder.mutation<string, { stationUUID: string }>({
      query: ({ stationUUID }) => ({
        url: `${DELETE_FUEL_STATIONS}/${stationUUID}`,
        method: 'DELETE',
      }),
    }),
    addStation: builder.mutation<
      IFuelStation,
      Omit<IFuelStation, 'id' | 'uuid' | 'createdAt' | 'updatedAt'>
    >({
      query: ({ ...patch }) => ({
        url: `${FETCH_FUEL_STATIONS}`,
        body: patch,
        method: 'POST',
      }),
    }),
    updateStation: builder.mutation<
      IFuelStation,
      Omit<IFuelStation, 'id' | 'createdAt' | 'updatedAt'>
    >({
      query: ({ ...patch }) => ({
        url: `${UPDATE_FUEL_STATIONS}`,
        body: patch,
        method: 'PUT',
      }),
    }),
  }),
});

export const {
  useFetchAllStationsQuery,
  useAddStationMutation,
  useUpdateStationMutation,
  useDeleteStationMutation,
} = stationApi;
