import { createApi } from '@reduxjs/toolkit/query/react';
import {
  FETCH_CORPORATE_TRANSACTIONS,
  FETCH_TRANSACTIONS,
} from '../../constants/routes';
import {
  IGetTransactions,
  ITransactionQuery,
  ITransactionResponse,
} from '../../types/transaction';
import isEmpty from 'lodash/isEmpty';
import { staggeredBaseQuery } from '../base-query';

export const transactionsApi = createApi({
  reducerPath: 'transactions',
  baseQuery: staggeredBaseQuery,
  endpoints: (builder) => ({
    fetchAllTransactions: builder.query<
      ITransactionResponse,
      ITransactionQuery
    >({
      query: ({ ...params }) => ({
        url: FETCH_TRANSACTIONS,
        params,
        method: 'GET',
      }),
    }),
    fetchCorporateTransactions: builder.query<
      ITransactionResponse,
      IGetTransactions
    >({
      query: ({ companyUUID, ...params }) => ({
        url: `${FETCH_CORPORATE_TRANSACTIONS}${companyUUID ? `/${companyUUID}` : ''}`,
        params,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useFetchAllTransactionsQuery,
  useFetchCorporateTransactionsQuery,
} = transactionsApi;
